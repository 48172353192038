import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-box-adicionar-arquivo',
	templateUrl: './box-adicionar-arquivo.component.html',
	styleUrls: ['./box-adicionar-arquivo.component.scss'],
})
export class BoxAdicionarArquivoComponent {
	@Input() texto: string;
	@Input() icon: string;

	constructor() {}
}
