import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-atualizacao-cadastral-modal',
	templateUrl: './atualizacao-cadastral-modal.component.html',
	styleUrls: ['./atualizacao-cadastral-modal.component.scss'],
})
export class AtualizacaoCadastralModalComponent implements OnInit {
	ngOnInit(): void {}
}
