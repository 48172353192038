import { Injectable, ViewContainerRef } from '@angular/core';
import { Bs2AutenticacaoService, Bs2ModalService, Bs2RestClientService } from '@bs2/utilities-v9';
import { Observable, Subject } from 'rxjs';
import { AtualizacaoCadastralModalComponent } from 'src/app/shared/components/atualizacao-cadastral/atualizacao-cadastral-modal/atualizacao-cadastral-modal.component';
import { environment } from 'src/environments/environment';
import { AtualizacaoModel } from './models/Atualizacao.model';
import { AtualizacaoDadosEmpresaModel, AtualizacaoDadosEmpresaSemAlteracaoModel } from './models/AtualizacaoDadosEmpresa.model';
import { AtualizarDadosSociosModel } from './models/AtualizarDadosSocios.model';
import { StatusModel } from './models/Status.model';
import { UploadModel } from './models/Upload.model';

@Injectable({
	providedIn: 'root',
})
export class AtualizacaoCadastralService {
	constructor(
		private autenticacaoService: Bs2AutenticacaoService,
		private bs2RestClientService: Bs2RestClientService,
		private modalService: Bs2ModalService
	) {}

	status(): Observable<StatusModel> {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		return this.bs2RestClientService.get<StatusModel>(`${environment.baseURL}/empresas/atualizacao-cadastral/status`, {
			hideErrorToast: true,
			jweFull: true,
		});
	}

	updateSocios(dadosSocios: AtualizarDadosSociosModel): Observable<AtualizacaoModel> {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		return this.bs2RestClientService.patch<AtualizacaoModel>(
			`${environment.baseURL}/empresas/atualizacao-cadastral/socios`,
			{
				body: dadosSocios,
				jweFull: true,
			}
		);
	}

	updateEmpresa(dadosEmpresa: AtualizacaoDadosEmpresaModel | AtualizacaoDadosEmpresaSemAlteracaoModel): Observable<AtualizacaoModel> {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		return this.bs2RestClientService.patch<AtualizacaoModel>(`${environment.baseURL}/empresas/atualizacao-cadastral`, {
			body: dadosEmpresa,
			jweFull: true,
		});
	}

	confirmar(atualizacaoId): Observable<any> {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		return this.bs2RestClientService.patch<any>(`${environment.baseURL}/empresas/atualizacao-cadastral/conclusao`, {
			body: {
				atualizacaoId,
			},
			jweFull: true,
		});
	}

	uploadArquivo(arquivo, atualizacaoId) {
		const empresaId = this.autenticacaoService.activeEmpresa.id;

		const formData = new FormData();

		formData.append('arquivo', arquivo[0]);
		formData.append('atualizacaoId', atualizacaoId);

		return this.bs2RestClientService.post<UploadModel>(`${environment.baseURL}/empresas/atualizacao-cadastral/arquivos`, {
			body: formData,
		});
	}

	openModal(viewRef: ViewContainerRef, skip: boolean, status: StatusModel = null) {
		this.modalService.open(viewRef, {
			childComponent: AtualizacaoCadastralModalComponent,
			data: { status, skip },
			width: '600px',
		});
	}
}
