import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-progresso-envio',
	templateUrl: './progresso-envio.component.html',
	styleUrls: ['./progresso-envio.component.scss'],
})
export class ProgressoEnvioComponent implements OnInit {
	@Input() conclusao: boolean;
	@Input() velocidade: string;
	@Input() erro: boolean;

	progresso;

	velocidadeMap = {
		rapido: 5,
		normal: 10,
		lento: 20,
	};

	constructor() {}

	ngOnInit() {
		this.progresso = this.conclusao === true ? 100 : 0;
		this.contagemProgresso();
	}

	contagemProgresso() {
		let stepperProgresso;

		stepperProgresso = setInterval(() => {
			this.progresso < 90 ? this.progresso++ : '';

			if (this.conclusao || this.erro) {
				while (this.progresso < 100) {
					this.progresso++;
				}
				this.fim();
			}
		}, this.velocidadeMap[this.velocidade]);
	}

	fim() {
		let count = 0;

		while (count < 1000) {
			clearInterval(count);
			count++;
		}
	}
}
