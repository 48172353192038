import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { Bs2AutenticacaoService, Bs2ManagementStorageService, Bs2SessionStorageService, StorageKeyEnum, getPartner } from '@bs2/utilities-v9';
import { EmpresasService } from '../services/empresas/empresas.service';


@Injectable({
	providedIn: 'root',
})
export class AutenticacaoGuard  {
	constructor(
		private autenticacaoService: Bs2AutenticacaoService,
		private router: Router,
		private empresasService: EmpresasService,
		private storageService: Bs2SessionStorageService,
		public bs2ManagementStorageService: Bs2ManagementStorageService
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

		const isRedirectCorporate = this.bs2ManagementStorageService.getBySlug(StorageKeyEnum.redirectLoginCorporate);


		try {
			if (this.autenticacaoService.isAutenticado()) {
				this.empresasService.init();
				return true;
			} else {
				let redirectUrl = window.location.pathname;
				if (redirectUrl !== `/${getPartner()}`) {
					this.storageService.setItem('redirectUrl', redirectUrl);
				}

				this.router.navigateByUrl('/autenticacao');
				return false;
			}
		} catch (e) {
			if (!isRedirectCorporate) {
				this.autenticacaoService.logout();
			}
			this.router.navigate(['/autenticacao']);
		}
	}
}
