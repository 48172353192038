import { Component, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';
import { Bs2ToastService, ToastTipo } from '@bs2/utilities-v9';
import { Subject } from 'rxjs';
import { AtualizacaoCadastralService } from 'src/app/core/services/atualizacao-cadastral/atualizacao-cadastral.service';
import { TipoDocumentoEnum } from 'src/app/core/services/atualizacao-cadastral/enums/TipoDocumento.enum';
import { tiposPermitidos } from 'src/app/core/services/atualizacao-cadastral/helpers/TiposPermitidos';
import { SocioParaAlterarModel } from 'src/app/core/services/atualizacao-cadastral/models/SocioParaAlterar.model';
import { TipoSocioEnum } from 'src/app/core/services/empresas/enums/TipoSocio.enum';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-box-upload',
	templateUrl: './box-upload.component.html',
	styleUrls: ['./box-upload.component.scss'],
})
export class BoxUploadComponent implements OnInit {
	@Output() sociosChange: Subject<SocioParaAlterarModel[]>;
	@Input('atualizacaoId') atualizacaoId: string;
	@Input('socios') socios: SocioParaAlterarModel[];
	//  = [
	// 	{
	// 		administrador: false,
	// 		dataNascimento: new Date(),
	// 		documentoPessoal: {
	// 			emitidoEm: new Date(),
	// 			numeroRegistro: '123',
	// 			orgaoExpedidor: 'SSP',
	// 			tipo: TipoDocumentoEnum.RG,
	// 			ufExpedidor: 'SP',
	// 			uploadsIds: [],
	// 		},
	// 		documento: '41459438809',
	// 		email: 'email@email.com',
	// 		endereco: {
	// 			bairro: 'bairro',
	// 			cep: '04115080',
	// 			cidade: 'cidade',
	// 			logradouro: 'endereco',
	// 			numero: '12',
	// 			uf: 'SP'
	// 		},
	// 		nome: 'Adriano Moura',
	// 		nomeMae: 'Mae do Adriano',
	// 		percentualSocietario: 50,
	// 		telefone: '11940052458'
	// 	},
	// 	{
	// 		administrador: false,
	// 		dataNascimento: new Date(),
	// 		documentoPessoal: {
	// 			emitidoEm: new Date(),
	// 			numeroRegistro: '123',
	// 			orgaoExpedidor: 'SSP',
	// 			tipo: TipoDocumentoEnum.CNH,
	// 			ufExpedidor: 'SP',
	// 			uploadsIds: [],
	// 		},
	// 		documento: '41459438809',
	// 		email: 'email@email.com',
	// 		endereco: {
	// 			bairro: 'bairro',
	// 			cep: '04115080',
	// 			cidade: 'cidade',
	// 			logradouro: 'endereco',
	// 			numero: '12',
	// 			uf: 'SP'
	// 		},
	// 		nome: 'Adriano Moura 2',
	// 		nomeMae: 'Mae do Adriano',
	// 		percentualSocietario: 50,
	// 		telefone: '11940052458'
	// 	},
	// ];

	@ViewChild('accordionSocio', { static: false }) accordionSocio: MatAccordion;

	controleArquivos: {
		documentoSocio: string;
		tipoDoc: TipoDocumentoEnum;
		frente: {
			loadArquivo: boolean;
			loadArquivoConclusao: boolean;
			file: any;
			uploadId: string;
		};
		verso: {
			loadArquivo: boolean;
			loadArquivoConclusao: boolean;
			file: any;
			uploadId: string;
		};
	}[];

	constructor(private atualizacaoCadastralService: AtualizacaoCadastralService, private toastService: Bs2ToastService) {
		this.sociosChange = new Subject<SocioParaAlterarModel[]>();
	}

	ngOnInit() {
		this.controleArquivos = this.socios.map((s) => {
			return {
				documentoSocio: s.documento,
				tipoDoc: s.documentoPessoal.tipo,
				frente: {
					file: null,
					loadArquivo: false,
					loadArquivoConclusao: false,
					uploadId: null,
				},
				verso: {
					file: null,
					loadArquivo: false,
					loadArquivoConclusao: false,
					uploadId: null,
				},
			};
		});
	}

	enviarDocSocio(event, socioIndex, socioDocumento, lado) {
		this.controleArquivos[socioIndex].tipoDoc = this.socios[socioIndex].documentoPessoal.tipo;
		this.controleArquivos[socioIndex][lado].loadArquivo = false;

		const fileList = event.target.files as FileList;

		if (fileList[0].size > 0 && fileList[0].size < environment.maxFileSize) {
			const tipoPermitido = tiposPermitidos.find((tipo) => tipo === fileList[0].type);

			if (tipoPermitido) {
				if (fileList.length > 0) {
					this.controleArquivos[socioIndex][lado].loadArquivo = true;
					this.controleArquivos[socioIndex][lado].loadArquivo = true;

					if (lado === 'frente') {
						Array.from(fileList).map((documentoSocio) => {
							this.controleArquivos[socioIndex] ? (this.controleArquivos[socioIndex].frente.file = documentoSocio) : [];
						});
					}

					if (lado === 'verso') {
						Array.from(fileList).map((documentoSocio) => {
							this.controleArquivos[socioIndex] ? (this.controleArquivos[socioIndex].verso.file = documentoSocio) : [];
						});
					}

					this.atualizacaoCadastralService.uploadArquivo(fileList, this.atualizacaoId).subscribe(
						(ret) => {
							lado === 'frente'
								? (this.controleArquivos[socioIndex].frente.uploadId = ret.uploadId)
								: (this.controleArquivos[socioIndex].verso.uploadId = ret.uploadId);

							this.controleArquivos[socioIndex][lado].loadArquivoConclusao = true;

							if (this.controleArquivos[socioIndex].tipoDoc === TipoDocumentoEnum.CNH) {
								this.accordionSocio.closeAll();
							} else if (this.controleArquivos[socioIndex].tipoDoc === TipoDocumentoEnum.RG) {
								if (
									this.controleArquivos[socioIndex].frente.loadArquivoConclusao &&
									this.controleArquivos[socioIndex].verso.loadArquivoConclusao
								) {
									this.accordionSocio.closeAll();
								}
							} else if (this.controleArquivos[socioIndex].tipoDoc === TipoDocumentoEnum.RNE) {
								if (
									this.controleArquivos[socioIndex].frente.loadArquivoConclusao &&
									this.controleArquivos[socioIndex].verso.loadArquivoConclusao
								) {
									this.accordionSocio.closeAll();
								}
							}

							this.emitSocioUpload();
						},
						(error) => {
							this.controleArquivos[socioIndex][lado].uploadId = null;
							this.controleArquivos[socioIndex][lado].erro = true;
							this.controleArquivos[socioIndex][lado].loadArquivoConclusao = false;
							this.controleArquivos[socioIndex][lado].loadArquivo = false;
							throw new Error(error);
						}
					);
				}
			} else {
				this.toastService.open('Formato de arquivo não permitido', ToastTipo.Erro, 5000);
			}
		} else {
			const errorMessage = fileList[0].size === 0 ? 'Arquivo não deve ser vazio' : 'Tamanho máximo para arquivos é de 5 Mb';
			this.toastService.open(errorMessage, ToastTipo.Erro, 5000);
		}
	}

	emitSocioUpload() {
		const socios: SocioParaAlterarModel[] = this.socios.map((s, i) => {
			s.documentoPessoal.uploadsIds = [];

			if (this.controleArquivos[i].frente.uploadId) {
				s.documentoPessoal.uploadsIds.push(this.controleArquivos[i].frente.uploadId);
			}
			if (this.controleArquivos[i].verso.uploadId) {
				s.documentoPessoal.uploadsIds.push(this.controleArquivos[i].verso.uploadId);
			}

			return s;
		});

		this.sociosChange.next(socios);
	}

	selecionarDocSocio(event, index) {
		const inputFile: HTMLElement = event.target.children[0];

		if (inputFile) {
			inputFile.click();
		}
	}

	isConcluido(socioIndex, lado) {
		return this.controleArquivos[socioIndex][lado].loadArquivoConclusao;
	}

	hasErro(socioIndex, lado) {
		return this.controleArquivos[socioIndex][lado].erro;
	}

	removerDocumento(tipo: string, lado?: string, socioIndex?: number) {
		this.controleArquivos[socioIndex][lado] = {
			file: null,
			loadArquivo: false,
			loadArquivoConclusao: false,
			uploadId: null,
		};

		this.emitSocioUpload();
	}

	get TipoSocioEnum() {
		return TipoSocioEnum;
	}

	get TipoDocumentoEnum() {
		return TipoDocumentoEnum;
	}
}
