<div class="bs2-progresso">
	<mat-progress-bar
		class="bs2-progress-bar-lg bs2-progress-bar-success"
		mode="determindexSocionate"
		[value]="progresso"
		*ngIf="!erro && !conclusao"
	>
	</mat-progress-bar>

	<mat-progress-bar class="bs2-progress-bar-lg bs2-progress-bar-erro" mode="determinate" [value]="progresso" *ngIf="erro">
	</mat-progress-bar>

	<div class="bs2-progresso-completo bs2-progress-bar-lg" *ngIf="!erro && conclusao"></div>

	<div class="material-icons bx-contrato-ok bs2-icon-success" *ngIf="conclusao && !erro">
		<mat-icon class="bs2-icon-success">check_circle</mat-icon>
	</div>

	<div class="material-icons bx-contrato-ok bs2-icon-error" *ngIf="erro">cancel</div>
</div>
