import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Bs2OatService } from '@bs2/utilities-v9';

@Injectable()
export class UnauthenticatedTokenInterceptor implements HttpInterceptor {

	endpoints = [
		'recuperacoes/senhas',
		'recuperacoes/tokens/usuarios',
		'qrcodes/forfrontend/spa/v1/logins/qrcodes'
	]

	constructor(private readonly oatService: Bs2OatService) { }

	intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		let newReq = request;

		let inListCount = 0;
		this.endpoints.forEach((edp) => {
			if (newReq.url.includes(edp)) {
				inListCount = inListCount + 1;
			}
		})

		if (inListCount > 0 && !newReq.headers.get('authorization')) {
			newReq = newReq.clone({
				headers: newReq.headers.set('authorization', 'Basic ' + window.btoa(`${this.oatService.retrieveOatValue('bvtus')}:${this.oatService.retrieveOatValue('bvtps')}`)),
			});
		}

		return next.handle(newReq);
	}
}
