import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "./material/material.module";
import { Bs2ComponentsModule } from "@bs2/utilities-v9";
import { ContainerAuthenticationComponent } from "./components/container-authentication/container-authentication.component";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { NotificacoesComponent } from "./components/notificacoes/notificacoes.component";
import { ChecarPermissaoDirective } from "./directives/checarPermissao/checar-permissao.directive";
import { ActiveFiltersBarComponent } from "./components/active-filters-bar/active-filters-bar.component";
import { ContainerMiddleComponent } from "./components/container-middle/container-middle.component";
import { SafeStylePipe } from "./pipes/safe-style/safe-style.pipe";
import { ModalNpsComponent } from "./components/modal-nps/modal-nps.component";
import { TextEllipsisPipe } from "./pipes/text-ellipsis/text-ellipsis.pipe";
import { ModalPixComponent } from "./components/pix/modal-pix/modal-pix.component";
import { ModalPixPreCadastroSucessoModalComponent } from "./components/pix/modal-pix-pre-cadastro-sucesso/modal-pix-pre-cadastro-sucesso.component";
import { ModalPixCadastroSucessoModalComponent } from "./components/pix/modal-pix-cadastro-sucesso/modal-pix-cadastro-sucesso.component";
import { ModalPixPreCadastroComponent } from "./components/pix/modal-pix-pre-cadastro/modal-pix-pre-cadastro.component";
import { ModalPixTwofactorComponent } from "./components/pix/modal-pix-twofactor/modal-pix-twofactor.component";
import { ModalPixJanelaEncerradaComponent } from "./components/pix/modal-pix-janela-encerrada/modal-pix-janela-encerrada.component";
import { ModalPixAcessarIBComponent } from "./components/pix/modal-pix-acessar-ib/modal-pix-acessar-ib.component";
import { AtualizacaoCadastralModalComponent } from "./components/atualizacao-cadastral/atualizacao-cadastral-modal/atualizacao-cadastral-modal.component";
import { BoxAdicionarArquivoComponent } from "./components/atualizacao-cadastral/box-adicionar-arquivo/box-adicionar-arquivo.component";
import { BoxUploadComponent } from "./components/atualizacao-cadastral/box-upload/box-upload.component";
import { ProgressoEnvioComponent } from "./components/atualizacao-cadastral/progresso-envio/progresso-envio.component";
import { NgxMaskModule } from "ngx-mask";
import { CurrencyMaskModule } from "ng2-currency-mask";
import { ChecarPermissaoPixDirective } from "./directives/checarPermissaoPix/checar-permissao-pix.directive";
import { ModalEnviarQRCodeEmailPixComponent } from "./components/pix/modal-envio-pagamento-email/modal-enviar-qrcode-email-pix.component";
import { ModalMigracaoComponent } from "./components/migracao-conta-pagamento/modal-migracao/modal-migracao.component";
import { ModalMigracaoSucessoComponent } from "./components/migracao-conta-pagamento/modal-migracao-sucesso/modal-migracao-sucesso.component";
import { ModalAberturaNovaContaModalComponent } from "./components/segunda-conta/nova-conta-abertura/modal-nova-conta-abertura.component";
import { ModalApresentacaoNovaContaModalComponent } from "./components/segunda-conta/nova-conta-apresentação/modal-nova-conta-apresentacao.component";
import { ModalRespostaLimitesComponent } from "./components/pix/modal-resposta-limites/modal-resposta-limites.component";
import { ModalInformativoComponent } from "./components/pix/modal-informativo/modal-informativo.component";
import { ModalSolicitarAumentoLimiteComponent } from "./components/pix/modal-solicitar-aumento-limite/modal-solicitar-aumento-limite.component";
import { OnboardSucessoComponent } from "./components/onboard-sucesso/onboard-sucesso.component";
import { ModalAvisoDadosPixComponent } from "./components/aviso-dados-pix/modal-aviso-dados-pix.component";
import { MaskDocumentPipe } from "./pipes/mask-document/mask-document.pipe";
import { ModalAvisoLoginTwoFactorAuthComponent } from "./components/modal-aviso-login-two-factor-auth/modal-aviso-login-two-factor-auth.component";
import { ModalGalapagosComponent } from './components/modal-galapagos/modal-galapagos.component';
import { Bs2AtualizacaoCadastralBaseComponent } from './components/atualizacao-cadastral/bs2-atualizacao-cadastral-base/bs2-atualizacao-cadastral-base.component';
import { ObserversModule } from '@angular/cdk/observers';

@NgModule({
    declarations: [
        ContainerAuthenticationComponent,
        ContainerMiddleComponent,
        NotificacoesComponent,
        ChecarPermissaoDirective,
        ChecarPermissaoPixDirective,
        ActiveFiltersBarComponent,
        SafeStylePipe,
        ModalNpsComponent,
        TextEllipsisPipe,
        ModalPixComponent,
        ModalPixPreCadastroSucessoModalComponent,
        ModalPixCadastroSucessoModalComponent,
        ModalPixPreCadastroComponent,
        ModalPixTwofactorComponent,
        ModalPixJanelaEncerradaComponent,
        ModalEnviarQRCodeEmailPixComponent,
        ModalPixAcessarIBComponent,
        AtualizacaoCadastralModalComponent,
        BoxAdicionarArquivoComponent,
        BoxUploadComponent,
        ProgressoEnvioComponent,
        ModalMigracaoComponent,
        ModalMigracaoSucessoComponent,
        ModalAvisoDadosPixComponent,
        ModalAberturaNovaContaModalComponent,
        ModalApresentacaoNovaContaModalComponent,
        ModalRespostaLimitesComponent,
        ModalInformativoComponent,
        ModalSolicitarAumentoLimiteComponent,
        OnboardSucessoComponent,
        MaskDocumentPipe,
        ModalAvisoLoginTwoFactorAuthComponent,
        ModalGalapagosComponent,
        AtualizacaoCadastralModalComponent,
        Bs2AtualizacaoCadastralBaseComponent,
    ],
    imports: [
        RouterModule,
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        Bs2ComponentsModule,
        CurrencyMaskModule,
        NgxMaskModule.forRoot(),
				ObserversModule,
    ],
    exports: [
        ContainerAuthenticationComponent,
        ContainerMiddleComponent,
        NotificacoesComponent,
        ChecarPermissaoDirective,
        ChecarPermissaoPixDirective,
        ActiveFiltersBarComponent,
        SafeStylePipe,
        ModalNpsComponent,
        TextEllipsisPipe,
        MaskDocumentPipe,
        AtualizacaoCadastralModalComponent,
        Bs2AtualizacaoCadastralBaseComponent,
    ]
})
export class SharedModule {}
